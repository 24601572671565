import React from "react";

import Section from "../../../HOC/Section";

import blogImage1 from "../../../assets/img/blog1.webp";
import blogImage2 from "../../../assets/img/blog2.webp";
import blogImage3 from "../../../assets/img/blog3.webp";

const Blog = () => {
  return (
    <Section id="blog">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span> </span>Blog
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto"></h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage1} className="card-img-top" alt="Muğla Taksi" title="Muğla Menteşe Taksi"/>
                <div className="card-body">
                  <h5 className="card-title">TAKSİDE KART KULLANIMI</h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    Kredi kartı kullanımıyla ilgili bilgileri genel bilgiler;
                    <br />
                    <b>Önceden Soruşturma Yapın:</b> Tatile veya iş seyahatine
                    çıkarken, hedef şehirdeki taksi hizmetlerinin genellikle
                    kredi kartı kabul edip etmedikleri konusunda önceden bir
                    araştırma yapmanız faydalı olacaktır.
                    <br />
                    <b>Taksi Şirketleri:</b> Birçok büyük şehirdeki taksi
                    şirketleri, kredi kartı ödemelerini kabul eden bir terminal
                    veya uygulama kullanmaktadır. Çoğu durumda, sürücüler de
                    mobil ödeme sistemlerini kullanabilir.
                    <br />
                    <b>Mobil Uygulamalar:</b> Bazı şehirlerde, taksi çağırmak ve
                    ödeme yapmak için mobil uygulamalar kullanılabilir. Bu
                    uygulamalar genellikle kredi kartı bilgilerinizi
                    kaydedebilmenize olanak tanır.
                    <br />
                    <b>Terminal Kullanımı:</b> Taksilerde sıkça görülen bir
                    uygulama, aracın içinde bir kredi kartı terminali
                    kullanmaktır. Sürücü, seyahatin sonunda kredi kartınızı bu
                    terminal aracılığıyla kullanarak ödeme alır.
                    <br />
                    <b>Nakit Seçeneği:</b> Bazı durumlarda, taksiler sadece
                    nakit ödemeleri kabul edebilir. Bu nedenle, her ihtimale
                    karşı yanınızda nakit bulundurmanızda fayda olabilir. Taksi
                    hizmetlerinin ödeme seçenekleri, yerel düzenlemelere ve
                    şirket politikalarına bağlı olarak değişebilir. Bu nedenle,
                    seyahat ettiğiniz bölgeye özgü politikaları öğrenmek için
                    taksi şirketlerinin resmi web sitelerini veya müşteri
                    hizmetleriyle iletişim kurmayı düşünebilirsiniz.
                  </p>
                  <a
                    href="https://maps.app.goo.gl/ACXHHf4nW2NXDwPj7"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage2} className="card-img-top" alt="Muğla Taksi" title="Muğla Menteşe Taksi" />
                <div className="card-body">
                  <h5 className="card-title">TARİFE BİLGİSİ</h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    Taksilerde tarife hesaplaması, genellikle belirli unsurlara
                    dayalı bir formül kullanılarak yapılır. Ancak, bu unsurlar
                    ve formüller, ülkeden ülkeye, şehirden şehire ve hatta
                    şirketten şirkete değişiklik gösterebilir. <br />
                    <b>Başlangıç Ücreti:</b> Yolculuğun başlaması için ödenen
                    sabit bir ücrettir. Bu ücret, taksinin hareket etmeye
                    başladığı andan itibaren geçerlidir. <br />
                    <b>Kilometre Başı Ücret (Mesafe Ücreti):</b> Taksimetre
                    genellikle belirli bir mesafe birimine göre ücret hesaplar.
                    Bu birim genellikle kilometredir, ancak bazı yerlerde mil
                    veya başka bir ölçü birimi de kullanılabilir. <br />{" "}
                    <b>Zaman Başı Ücret (Bekleme Ücreti):</b> Araç, trafikte
                    veya yolcuyu alırken beklediği süre boyunca geçen zamanı baz
                    alarak ek bir ücret uygulayabilir. Bu ücret, taksinin
                    hareket etmediği durumlarda devreye girebilir. <br />
                    <b>Hava Durumu veya Trafik Durumu:</b> Bazı yerlerde, yoğun
                    trafik veya kötü hava koşulları gibi faktörlere bağlı olarak
                    tarifede değişiklikler olabilir.
                    <br />
                    <br />
                    Taksilerin tarife politikaları genellikle yerel
                    düzenlemelere tabidir ve belirli bir bölgede faaliyet
                    gösteren taksilerin bu kurallara uymaları beklenir.
                    Taksilerde kullanılan taksimetreler, bu tarife unsurlarını
                    otomatik olarak hesaplar ve sürücü tarafından kontrol
                    edilemez. Yolculuk sonunda, ödenmesi gereken ücret
                    taksimetre üzerinden okunarak belirlenir.
                  </p>
                  <a
                    href="https://maps.app.goo.gl/ACXHHf4nW2NXDwPj7"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="card rounded-0">
                <img src={blogImage3} className="card-img-top" alt="Muğla Taksi" title="Muğla Menteşe Taksi" />
                <div className="card-body">
                  <h5 className="card-title">DİKKAT EDİLMESİ GEREKENLER</h5>
                  <p className="card-text" style={{ height: "auto" }}>
                    <br />
                    <b>Resmi Taksileri Tercih Edin: </b>
                    Resmi ve lisanslı taksileri kullanmaya özen gösterin.
                    Genellikle resmi taksiler, belirli renk ve logosuyla
                    tanınabilir.
                    <br />
                    <b>Sürücü Kimliği ve Plaka Kontrolü:</b> Taksiye binmeden
                    önce sürücünün kimlik kartını kontrol edin ve aracın
                    plakasını not alın. Bu bilgileri bir yakınınıza veya
                    güvendiğiniz bir arkadaşınıza bildirin. <br />
                    <b>Kredi Kartı ve Nakit Ödeme:</b> Eğer mümkünse, önceden
                    belirlenen bir ücret üzerinden kredi kartı ile ödeme yapın
                    veya takside nakit ödemek üzere yeterli miktarı hazır
                    bulundurun. <br />
                    <b>Kapıları Kilitli Tutun: </b> Özellikle trafikte dururken
                    veya sinyalde beklerken kapıları açık bırakmaktan kaçının.{" "}
                    <br />
                    <b>Yolculuk Bilgilerini Paylaşın:</b> Yolculuk bilgilerinizi
                    bir arkadaşınızla veya ailenizle paylaşın. Taksi plakası,
                    sürücü bilgileri ve tahmini varış saatiniz gibi bilgileri
                    belirtin. <br />
                    <b>Taksi İçinde Telefon Kullanımı:</b>
                    Özellikle harita uygulamalarını kullanırken ve sosyal
                    medyada paylaşım yaparken çevrenizi gözden kaçırmamaya özen
                    gösterin. <br />
                    <b>Yolculuk İçin Bekleme Alanları:</b> Mümkünse, resmi taksi
                    duraklarını veya güvenilir taksi şirketlerini tercih edin.{" "}
                    <br />
                    <b>Şüpheli Durumları Bildirin: </b>
                    Şüpheli durumlarla karşılaştığınızda veya güvenlik
                    endişeleriniz olduğunda hemen polisi veya taksi şirketini
                    arayarak durumu bildirin.
                  </p>
                  <a
                    href="https://maps.app.goo.gl/ACXHHf4nW2NXDwPj7"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-primary rounded-0 mr-2"
                  >
                    Daha Fazla Bilgi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Blog;
