import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1101.936160039791!2d28.353247070536522!3d37.215954291846124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf73e024ea274d%3A0x8c546dc49fff05de!2zTXXEn2xhIE1lbnRlxZ9lIFpFWUJFSyBUQUtTxLA!5e0!3m2!1str!2str!4v1702590746933!5m2!1str!2str'
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />

       
      </div>
      
    </Section>
    
  );
};

export default mapMarker;
