import React from "react";

import Section from "../../../HOC/Section";
import aboutImage from "../../../assets/img/zeybek14.png";

const about = () => {
  return (
    <Section id="about">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>HAKKIMIZDA</span>
          </h3>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 col-lg-6 mb-3">
              <div className="aboutImage">
                <img
                  src={aboutImage}
                  alt="Muğla Menteşe Taksi"
                  title="MUĞLA MENTEŞE TAKSİ - MUĞLA ACİL TAKSİ - 7/24 TAKSİ"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <h5 className="about-title"></h5>
              <div className="about-description">
                <p style={{ color: "black" }}>
                  2016 yılından bu yana Muğla Menteşe Zeybek Taksi olarak
                  faaliyet göstermekteyiz. Ekibimiz seçkin ve tecrübeli
                  kişilerden oluşup, tüm araçlarımız yeni model, klimalı, konforlu ve güvenlidir.
                  Siz değerli müşterilerimizin 24 saat huzur ve güven
                  içerisinde şehir içi ve şehir dışı ulaşımlarınızda Muğla
                  Zeybek Taksi misafir perverliği ve kalitesiyle
                  hizmetinizdeyiz.
                </p>
                <h5 className="about-title" style={{ color: "black" }}>
                  7/24 Taksi Hizmeti
                </h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                    Güvenilir şoför ağımızdan oluşan araçlarımız 7/24
                    hizmetinizdedir. Araçlarımız klimalıdır. Ayrıca
                    ödemelerinizi kredi kartı ile yapabilirsiniz.
                  </p>
                </div>

                <h5 className="about-title" style={{ color: "black" }}>
                  Güvenilir Hizmet
                </h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                    Taksilerimiz deneyimli şoför ve klimalı araçlardan oluşan
                    güvenli bir ekip tarafından kullanılmaktadır.
                  </p>
                </div>

                <h5 className="about-title" style={{ color: "black" }}>
                  Geniş Hizmet Ağı
                </h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                    Şehiriçi, şehirdışı, havaalanı taşımacılığı ve personel taşımacılığı dahil çok alanda yanınızdayız.
                  </p>
                </div>

                <h5 className="about-title" style={{ color: "black" }}>
                  Duyarlı Ekip
                </h5>
                <div className="about-description">
                  <p style={{ color: "black" }}>
                  Şehit aileleri için ücretsiz taşıma hizmeti vermekteyiz.
                  </p>
                </div>

                <a
                  href="https://maps.app.goo.gl/tw3vvuPNM2TGJdr17"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary rounded-0 mr-2"
                >
                  Daha Fazla Bilgi
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
