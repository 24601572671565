import React from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/sl2.jpg';

const Facts = () => {
  return (
    <Section id='facts'>
      <div
        className='facts-container'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className='container pt-5 pb-4'>
          <div className='row'>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-users' style={{color:"#FFBC01"}} />
              </div>
              <h4 className='facts-counter text-light'>25.000</h4>
              <h5 className='facts-title text-light'>Sefer</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-grin-beam' style={{color:"#FFBC01"}} />
              </div>
              <h4 className='facts-counter text-light'>75.000</h4>
              <h5 className='facts-title text-light'>Memnun Yolcu</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-project-diagram'  style={{color:"#FFBC01"}} />
              </div>
              <h4 className='facts-counter text-light'>5</h4>
              <h5 className='facts-title text-light'>Hizmet Veren Araç</h5>
            </div>
            <div className='col-sm-6 col-md-3 text-center mb-4'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-trophy'  style={{color:"#FFBC01"}} />
              </div>
              <h4 className='facts-counter text-light'>3</h4>
              <h5 className='facts-title text-light'>Kurumsal İtibar Ödülü</h5>
            </div>
          </div>
        </div>
        
      </div>
    </Section>
  );
};

export default Facts;
