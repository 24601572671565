import React from "react";

import Link from "../Link/Link";

const footer = () => {
  return (
    <footer className="">
      <div className="container text-light pt-5">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4 mb-5">
            <div className="footer-title">
              <h6>ZEYBEK TAKSİ</h6>
            </div>
            <div className="footer-content">
              <p style={{ color: "white" }}>
                <small className="text">
                  2016 yılından bu yana Muğla Menteşe ZEYBEK TAKSİ olarak faaliyet
                  göstermekteyiz. Ekibimiz seçkin ve tecrübeli kişilerden
                  oluşup, araçlarımız aynı marka ve aynı modele sahiptir. Siz
                  değerli müşterilerimizin 24 saat huzur ve güven içerisinde
                  şehir içi ve şehir dışı ulaşımlarınızda Muğla Menteşe Zeybek Taksi
                  misafir perverliği ve kalitesiyle hizmetinizdeyiz.
                </small>
              </p>
              <a
                href="https://maps.app.goo.gl/32gndaZrt55qCuzc6"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary rounded-0 mr-2"
              >
                Daha Fazla Bilgi
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-2 mb-5">
            <div className="footer-title">
              <h6>Bağlantılar</h6>
            </div>
            <div className="footer-content">
              <ul className="list-group quick-links">
                <li>
                  <Link target="home" offset={-120}>
                    Anasayfa
                  </Link>
                </li>
                <li>
                  <Link target="about">Hakkımızda</Link>
                </li>
                <li>
                  <Link target="services">Hizmetlerimiz</Link>
                </li>
                <li>
                  <Link target="blog">Blog</Link>
                </li>
                <li>
                  <Link target="contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Çalışma Saatleri</h6>
            </div>
            <div className="footer-content">
              <table class="table table-striped">
                <tbody>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Haftaiçi</td>
                    <td>7 Gün 24 Saat Açık </td>
                  </tr>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Cumartesi</td>
                    <td>7 Gün 24 Saat Açık</td>
                  </tr>
                  <tr
                    style={{ height: "2px", color: "white", fontSize: "small" }}
                  >
                    <td>Pazar</td>
                    <td>7 Gün 24 Saat Açık</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>İletişim Bilgileri</h6>
            </div>
            <div className="footer-content">
              <p className="text">
                <small>
                  {" "}
                  <span style={{ color: "yellow" }}>Adres : </span> Muslihittin Mah. Cem Sk. No:1, 48000  <br />
                  Menteşe / MUĞLA
                </small>
              </p>
              <p className="text">
                <small>
                  <span style={{ color: "yellow" }}>Telefon : </span> 0544 185 00 41
                </small>
              </p>
              <p className="text">
                <small>
                  <span style={{ color: "yellow" }}>E-mail : </span>{" "}
                  zeybektaksi41@gmail.com
                </small>
              </p>
              <div className="social-media mt-4">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-facebook-f mr-4" />
                </a>
                <a
                  href="https://wa.me/+905441850041"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-whatsapp mr-4" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  className="text-light"
                >
                  <i className="fab fa-instagram mr-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer pt-3 pb-3 text-center">
        <small>© All Right Reserved. Design By Berati Şahin</small>
      </div>
    </footer>
  );
};

export default footer;
