import React from "react";

const topBar = () => {
  return (
    <div
      className="top-bar pt-1 pb-1 text-light"
      style={{ backgroundColor: "#FFBC01" }}
    >
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="d-flex pt-2 pb-2">
            <div
              className="d-flex align-item-center mr-3"
              style={{ color: "black" }}
            >
              <a
                href="tel: +905441850041"
                target="_blank"
                className="text-dark"
              >
                <i className="fas fa-phone-alt mr-2" />
              </a>
              <small>0544 185 00 41</small>
            </div>
            <div
              className="d-flex align-item-center"
              style={{ color: "black" }}
            >
              <a
                href="mailto: zeybektaksi41@gmail.com"
                target="_blank"
                className="text-dark"
              >
                <i className="fas fa-envelope mr-2" />
              </a>

              <small>zeybektaksi41@gmail.com</small>
            </div>
          </div>
          <div className="d-flex pt-2 pb-2">
            <div
              className="social-media d-flex align-item-center"
              style={{ color: "black" }}
            >
              <a
                href="https://maps.app.goo.gl/uXRYZjHG7xAEXmwp7"
                target="_blank"
                className="text-dark"
              >
                <i className="fas fa-map-marked-alt mr-2" />
                <small style={{ marginRight: "2rem" }}>Konum</small>
              </a>
              <a
                href="https://wa.me/+905441850041"
                target="_blank"
                className="text-dark"
              >
                <i className="fab fa-whatsapp mr-2" />
                <small>Whatsapp</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default topBar;
