import React from "react";

import Section from "../../../HOC/Section";
import i1 from "./ServiceDetails/1.webp";
import i2 from "./ServiceDetails/2.webp";
import i3 from "./ServiceDetails/3.webp";
import i4 from "./ServiceDetails/4.webp";

const Service = () => {
  return (
    <Section id="services">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span>Verilen </span>Hizmetler
          </h3>
          <h6 className="section-subtitle mr-auto ml-auto"></h6>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i1}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="Muğla Taksi" title="Muğla Menteşe Taksi"
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#FFBC01", fontWeight: "bold" }}
                    >
                      Şehir İçi Taşımacılık
                    </h5>
                    <p class="card-text">
                    Şehiriçinde hedefinize çabucak varın.
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i2}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="Muğla Taksi" title="Muğla Menteşe Taksi"
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#FFBC01", fontWeight: "bold" }}
                    >
                      Şehir Dışı Taşımacılık
                    </h5>
                    <p class="card-text">
                    Şehirdışına hızlı ve güvenli taşımacılık.
 
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i3}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="Muğla Taksi" title="Muğla Menteşe Taksi"
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#FFBC01", fontWeight: "bold" }}
                    >
                      Havaalanı Taşımacılık
                    </h5>
                    <p class="card-text">
                    Uçağınıza kadar size eşlik ediyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="service-box d-flex">
                <div class="card">
                  <img
                    src={i4}
                    class="card-img-top"
                    style={{ width: "%100", height: "300px" }}
                    alt="Muğla Taksi" title="Muğla Menteşe Taksi"
                  ></img>
                  <div class="card-body">
                    <h5
                      className="service-title"
                      style={{ color: "#FFBC01", fontWeight: "bold" }}
                    >
                      Personel Taşımacılık
                    </h5>
                    <p class="card-text">
                    Özel anlarınızda taşıma hizmeti verilir.
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </Section>
  );
};

export default Service;
