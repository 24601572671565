import React from "react";
import Section from "../../../HOC/Section";

const Device = () => {
  return (
    <Section id="device">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h3 className="section-title">
            <span> </span>Neden Biz
          </h3>
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 col-lg-12 mb-12">
                <br />
                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#FFBC01" }}
                  > <i class="fa fa-money"></i> &nbsp;
                    <b>EKONOMİK FİYATLAR</b>
                    &nbsp; <i class="fa fa-money"></i> 
                  </li>
                  <li class="list-group-item">
                    Ekonomik taksi hizmetlerimiz ile siz değerli müşterilerimize
                    en kaliteli taksi hizmetlerini en uygun fiyatlara sunmaya
                    devam etmekteyiz.
                  </li>
                </ul>
                <br />
                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#FFBC01" }}
                  ><i class="fa fa-handshake"></i> &nbsp;
                    <b>MÜŞTERİ MEMNUNİYETİ</b>
                    &nbsp; <i class="fa fa-handshake"></i> 
                  </li>
                  <li class="list-group-item">
                    Müşteri memnuniyetinin işimizin geleceği ve teminatı
                    olduğunun bilinciyle işimizi en kaliteli şekilde
                    yapmaktayız.
                  </li>
                </ul>

                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#FFBC01" }}
                  ><i class="fa fa-clock"></i> &nbsp;
                    <b>7/24 TAKSİ</b>
                    &nbsp; <i class="fa fa-clock"></i> 
                  </li>
                  <li class="list-group-item">
                    Muğla Menteşe bölgesi başta olmak üzere müşterilerimize 7
                    gün 24 saat kesintisiz hizmet vermekteyiz.
                  </li>
                </ul>

                <ul class="list-group">
                  <li
                    class="list-group-item active"
                    aria-current="true"
                    style={{ backgroundColor: "#FFBC01" }}
                  ><i class="fa fa-taxi"></i> &nbsp;
                    <b>HIZLI TAKSİ</b> 
                    &nbsp; <i class="fa fa-taxi"></i> 
                  </li>
                  <li class="list-group-item">
                    Müşterilerimizin acil taksi ihtiyaçlarını uygun fiyatlarla
                    ve kaliteli hizmetlerle karşılamaktayız.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Device;
