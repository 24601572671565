import React from "react";

import Section from "../../../HOC/Section";
import bgImage from "../../../assets/img/sl1.jpg";
import Link from "../../UI/Link/Link";

const home = () => {
  return (
    <Section id="home">
      <div>
        <div
          className="home-content p-5"
          style={{ backgroundImage: `url(${bgImage})` }}
        >

          <div className="intro container text-center text-light">
            <h1 className="title">ZEYBEK TAKSİ</h1>
            <h2 className="sub-title mb-4">
              MUĞLA MENTEŞE ZEYBEK TAKSİ <br />
              <br />
              "Zeybek Taksi olarak Muğla şehrinde 7 gün 24 saat en uygun
              fiyat garantisi ve %100 müşteri memnuniyeti ile hizmet vermekteyiz. <br/>Deneyimli kadromuz ile siz değerli müşterilerimize hizmet
              vermekten mutluluk duyuyoruz. <br/>Not :
              Şehirlerarası yolculuklarında ekstra indirimler uygulanmaktadır.
              Bizimle görüşme sağlamadan yola çıkmayınız."
            </h2>
            <a
              href="https://wa.me/+905441850041"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-success rounded-0 mr-2"
            >
              Whatsapp
            </a>
            <a
              href="tel: +905441850041"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-primary rounded-0 mr-2"
            >
              Taksi Çağır
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
